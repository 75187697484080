<template>
  <!-- <div class="fix">© 2021 Nao,co.,Ltd.</div> -->
  <div></div>
</template>

<script>
export default {
  name: "FixNao",
};
</script>

<style scoped lang="less">
.fix {
  width: 210px;
  height: 33px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(16, 20, 38, 0.8);
  line-height: 25px;
  letter-spacing: 1px;
  position: absolute;
  right: 0px;
  bottom: 20px;
}
</style>