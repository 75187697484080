<template>
  <div class="listContiner">
    <div class="clothHistory">
      <span>{{ $t("downloadPage.txt33") }}...</span>
    </div>
    <div class="backGround"></div>
    <div class="weavingHistory">
      <div ref="downloadPDF" class="downloadPDF">
        <div ref="page1" class="pageTop">
          <!-- <div class="bg" v-show="exportType == 2"></div> -->
          <!-- 基本信息 -->
          <div class="basicInformation" ref="basicInformation">
            <!-- <div class="bg" v-show="exportType == 1"></div> -->
            <ul class="topLeft">
              <li>
                <img src="@/images/deletelogo.png" alt="" />
              </li>
              <li>
                <p>Visual Skeleton Key Ltd.</p>
                <p>www.naotextile.com</p>
                <p>nao.vsk.tech</p>
              </li>
            </ul>
            <ul class="topRight">
              <li>
                <span> {{ $t("downloadPage.txt1") }} </span>
                <span>{{ draperInfo.productCode }}</span>
              </li>
              <li>
                <span> {{ $t("downloadPage.txt2") }} </span>
                <span>{{ draperInfo.productName }}</span>
              </li>
              <!-- <li>
                <span> {{ $t("downloadPage.txt3") }} </span>
                <span>{{ draperInfo.customerName }}</span>
              </li> -->
              <li>
                <span> {{ $t("downloadPage.txt4") }} </span>
                <span>{{ draperInfo.createTime }}</span>
              </li>
              <!-- <li>
                <span> 备注 </span>
                <span
                  :style="draperInfo.remark.length > 15 ? style1 : style2"
                  >{{
                    draperInfo.remark.length > 0 ? draperInfo.remark : "无"
                  }}</span
                >
              </li> -->
            </ul>
          </div>
          <!--纱线信息-->
          <div class="yarnInfo" ref="yarnInfo">
            <!-- <div class="bg" v-show="exportType == 1"></div> -->
            <span class="title">(一){{ $t("downloadPage.txt5") }}</span>
            <div class="yarnListTable">
              <el-table
                :data="yarnList"
                class="yarnStoreTable"
                border
                style="width: 100%"
                :span-method="arraySpanMethod"
              >
                <el-table-column
                  prop="typeName"
                  label=""
                  width="180"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="yarnColor"
                  :label="$t('historyDetail.txt7')"
                  width="180"
                  align="center"
                >
                  <template slot-scope="scope">
                    <p class="bgColor">
                      <span
                        :style="{
                          background: scope.row.yarnColor,
                        }"
                      ></span>
                      {{ scope.row.colorCode }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="nr"
                  :label="$t('historyDetail.txt8')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="yarnColor"
                  :label="$t('historyDetail.txt9')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="rawMaterials"
                  :label="$t('historyDetail.txt10')"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="yarnCount"
                  :label="$t('historyDetail.txt11')"
                  align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <!-- 面料信息 -->
          <!-- productCode productName customerName machineTeam 
      fabricSize lineLength transverseDensity longitudinalDensity 
      gramWeight machineName  createTime  remark -->
          <div class="fabricInfo" ref="fabricInfo">
            <!-- <div class="bg" v-show="exportType == 1"></div> -->
            <div class="title">（二）{{ $t("downloadPage.txt11") }}</div>
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in fabricInfoData"
                :key="index"
              >
                <div class="itemTop">{{ item.title }}</div>
                <div class="itemCon" v-if="item.con != ''">{{ item.con }}</div>
                <div
                  class="itemCon text"
                  v-if="item.con == '' && item.title != ''"
                >
                  —
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="page2" class="pageTop">
          <!-- <div class="bg" v-show="exportType == 2"></div> -->
          <div class="setPageTop">
            <div class="item">
              <div class="itemtext headItem">{{ $t("downloadPage.txt1") }}</div>
              <div class="itemtext endItem">{{ draperInfo.productCode }}</div>
            </div>
          </div>
          <!-- 花型意匠图 -->
          <div ref="jacquardWeaveFront" class="jwWork">
            <!-- <div class="bg" v-show="exportType == 1"></div> -->
            <div class="leftTop">
              <span class="title">(三){{ $t("downloadPage.txt26") }}</span>
              <span>1.{{ $t("downloadPage.txt27") }}</span>
            </div>
            <div class="img">
              <div :style="getFrontUrlMap()" class="clothImg"></div>
            </div>
          </div>
        </div>
        <div ref="page3" class="pageTop" v-if="pdfShow">
          <!-- <div class="bg" v-show="exportType == 2"></div> -->
          <div class="setPageTop">
            <div class="item">
              <div class="itemtext headItem">{{ $t("downloadPage.txt1") }}</div>
              <div class="itemtext endItem">{{ draperInfo.productCode }}</div>
            </div>
          </div>
          <div class="pdfFabric">
            <div
              v-for="(item, index) in pdfImgList"
              :key="index"
              class="item model"
            >
              <span>{{ item.name }}</span>
              <img :src="item.url" alt="" />
            </div>
          </div>
        </div>
        <!-- 单面提花组织结构拼接图 -->
        <div class="structureSplice" ref="structureSplice">
          <!-- <div class="bg" v-show="exportType == 1"></div> -->
          <p class="title">(四){{ $t("downloadPage.txt34") }}</p>
          <div class="content">
            <span class="structureText">{{ $t("historyDetail.txt59") }}</span>
            <div class="structureImgTop">
              <div class="structureImg">
                <el-image
                  style="width: 98%; height: 98%"
                  :src="structureImg"
                  fit="contain"
                ></el-image>
              </div>
              <div class="structureColor">
                <div
                  v-for="(item, index) in structurecolor"
                  :key="index"
                  class="itemColor"
                  :style="index > 0 ? 'margin-top: 30px;' : ''"
                >
                  <span class="num">{{ index + 1 }}.</span>
                  <span
                    class="color"
                    :style="{ background: `rgb(${item.rgb})` }"
                  ></span>
                  <span class="name"></span>
                </div>
              </div>
            </div>
            <div
              class="itemLine"
              v-for="(item, index) in structureList"
              :key="index"
            >
              <div class="itemLeft itemContent">
                <div class="structureList">
                  <div class="item">
                    <div
                      v-for="(itemW, indexW) in structureList[index].keyList"
                      :key="indexW + 100"
                      class="itemW"
                    >
                      <div
                        v-for="(itemH, indexH) in itemW"
                        :key="indexH + 200"
                        :class="
                          indexH == 0
                            ? indexW == 0
                              ? structureList[index].keyList.length > 80 ||
                                itemW.length > 80
                                ? 'itemH leftBorder topBorder mixBox'
                                : 'itemH leftBorder topBorder'
                              : structureList[index].keyList.length > 80 ||
                                itemW.length > 80
                              ? 'itemH leftBorder mixBox'
                              : 'itemH leftBorder'
                            : indexW == 0
                            ? structureList[index].keyList.length > 80 ||
                              itemW.length > 80
                              ? 'itemH topBorder mixBox'
                              : 'itemH topBorder'
                            : structureList[index].keyList.length > 80 ||
                              itemW.length > 80
                            ? 'itemH mixBox'
                            : 'itemH'
                        "
                      >
                        <img :src="thStrList[itemH - 1]" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="colorlist">
                  <div
                    v-for="(itemW, indexW) in structureList[index].colorList"
                    :key="indexW + 300"
                    class="itemColor"
                    :style="{ background: `rgb(${itemW})` }"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div ref="page4" class="pageTop">
            <!-- <div class="bg" v-show="exportType == 2"></div> -->
            <div class="setPageTop">
              <div class="item">
                <div class="itemtext headItem">
                  {{ $t("downloadPage.txt1") }}
                </div>
                <div class="itemtext endItem">{{ draperInfo.productCode }}</div>
              </div>
            </div>
            <!-- 单面提花组织结构拼接图 -->
            <div class="structureSplice">
              <!-- <div class="bg" v-show="exportType == 1"></div> -->
              <p class="title">(四){{ $t("downloadPage.txt34") }}</p>
              <div class="content">
                <span class="structureText">{{
                  $t("historyDetail.txt59")
                }}</span>
                <div class="structureImgTop">
                  <div class="structureImg">
                    <el-image
                      style="width: 98%; height: 98%"
                      :src="structureImg"
                      fit="contain"
                      ><div slot="error" class="image-slot">
                        <!-- <i class="el-icon-picture-outline"></i> -->
                        <!-- <i class="el-icon-picture-outline"></i> -->
                        <img src="@/newImg/structure.png" alt="" />
                        <span>{{ $t("historyDetail.txt70") }}</span>
                      </div></el-image
                    >
                  </div>
                  <div class="structureColor">
                    <div
                      v-for="(item, index) in structurecolor"
                      :key="index"
                      class="itemColor"
                    >
                      <span class="num">{{ index + 1 }}.</span>
                      <span
                        class="color"
                        :style="{ background: `rgb(${item.rgb})` }"
                      ></span>
                      <span class="name"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="structureList.length > 0">
          <div
            :ref="`page${pageIndex + 5}`"
            class="pageTop"
            v-for="(page, pageIndex) in structureList"
            :key="page.num"
          >
            <!-- <div class="bg" v-show="exportType == 2"></div> -->
            <div class="setPageTop">
              <div class="item">
                <div class="itemtext headItem">
                  {{ $t("downloadPage.txt1") }}
                </div>
                <div class="itemtext endItem">{{ draperInfo.productCode }}</div>
              </div>
            </div>
            <!-- 单面提花组织结构拼接图 -->
            <div class="structureSplice">
              <!-- <div class="bg" v-show="exportType == 1"></div> -->
              <p class="title">(四){{ $t("downloadPage.txt34") }}</p>
              <div class="content">
                <div class="itemLine">
                  <div class="itemLeft itemContent">
                    <div class="structureList">
                      <div class="item">
                        <div
                          v-for="(itemW, indexW) in page.keyList"
                          :key="indexW + 100"
                          class="itemW"
                        >
                          <div
                            v-for="(itemH, indexH) in itemW"
                            :key="indexH + 200"
                            :class="
                              indexH == 0
                                ? indexW == 0
                                  ? page.keyList.length > 80 ||
                                    itemW.length > 80
                                    ? 'itemH leftBorder topBorder mixBox'
                                    : 'itemH leftBorder topBorder'
                                  : page.keyList.length > 80 ||
                                    itemW.length > 80
                                  ? 'itemH leftBorder mixBox'
                                  : 'itemH leftBorder'
                                : indexW == 0
                                ? page.keyList.length > 80 || itemW.length > 80
                                  ? 'itemH topBorder mixBox'
                                  : 'itemH topBorder'
                                : page.keyList.length > 80 || itemW.length > 80
                                ? 'itemH mixBox'
                                : 'itemH'
                            "
                          >
                            <img :src="thStrList[itemH - 1]" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="colorlist">
                      <div
                        v-for="(itemW, indexW) in page.colorList"
                        :key="indexW + 300"
                        class="itemColor"
                        :style="{ background: `rgb(${itemW})` }"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 面料效果图 -->
      <div class="center">
        <!-- 3D织物渲染图 -->
        <div class="Render3D" ref="mod3DImg">
          <!-- <div class="bg" v-show="exportType == 1"></div> -->
          <div class="topTittle" v-if="exportType == 1">
            <div class="itemTop">
              <span class="text">(四){{ $t("downloadPage.txt26") }}</span>
              <div class="item">
                <div class="itemtext headItem">
                  {{ $t("downloadPage.txt1") }}
                </div>
                <div class="itemtext endItem">{{ draperInfo.productCode }}</div>
                <!-- <span class="top">{{ "品号：" + draperInfo.productCode }}</span> -->
              </div>
            </div>
            <span class="text2">{{ renderText }}</span>
          </div>
          <div class="item">
            <Render
              v-if="renderType"
              :id="designId"
              :downLoad="true"
              :model="false"
              class="Render"
              ref="renderComp"
              @loadModelSuccess="loadModelSuccess"
            />
          </div>
        </div>
      </div>
    </div>
    <div style="height: 30px"></div>

    <FixNao></FixNao>
  </div>
</template>
<script>
import * as THREE from "three";
import oneSideimg from "@/components/oneSideimg/oneSideimg";
import FixNao from "@/components/FixNao/FixNao";
import doubleSideimg from "@/components/doubleSideimg/doubleSideimg";

import html2canvas from "html2canvas";
import { getClothDetail } from "@/api/history/history";
import Render from "@/components/Render/Render";
import jacquardWeaveMeanWork from "@/components/jacquardWeaveMeanWork/jacquardWeaveMeanWork";
import JSZip from "jszip";
import JsPDF from "jspdf";
import FileSaver from "file-saver";
import { washingImg } from "@/utils/data.js";

export default {
  name: "DownLoadPage",
  data() {
    return {
      columnsFirst: [" ", "A", "B", "C", "D", "纱线"],
      rightContent: new Array(30).fill(""),
      draperInfo: {
        productCode: "",
        productName: "",
        machineName: "",
        createTime: "",
        remark: "",
        categoryName: "",
      },
      triWork: [],
      valueList: [],
      roadNumber: "",
      designId: "",
      renderType: false,
      machineName: "",
      clothDetail: {},
      triWorkList: [],
      triWorkInfo: [], //三角工艺图参数
      needleList: [],
      needleInfo: [], //织针参数
      allMeanWorkList: new Array(72).fill([]),
      meanWorkInfo: [], //意匠图
      getNeedleWork: [],
      svgClass: ["san", "jiquan", "fuxian"],
      disabledSvgClass: ["san1", "jiquan1", "fuxian1"],
      iconClass: [
        require("@/images/oneSide/cqdownload.png"),
        require("@/images/oneSide/yjjiquan.png"),
        require("@/images/oneSide/yjfuxian.png"),
      ],
      disabledIconClass: [
        require("@/images/oneSide/san1.png"),
        require("@/images/oneSide/jiquan1.png"),
        require("@/images/oneSide/fuxian1.png"),
      ],
      imgUrl: [
        require("@/images/oneSide/chengquan.png"),
        require("@/images/oneSide/jiquan.png"),
        require("@/images/oneSide/fuxian.png"),
      ],
      disabledimgUrl: [
        require("@/images/oneSide/hschengquan.png"),
        require("@/images/oneSide/hsjiquan.png"),
        require("@/images/oneSide/hsfuxian.png"),
      ],
      designResultPath: "",
      clothImg: "",
      clothWidth: 1,
      clothheight: 1,
      sizeRepeatX: "",
      triWorkImg: "",
      colorList: [],
      triWorkImg11: "",
      style1: {
        "margin-left": 50 + "px",
        width: 250 + "px",
        "line-height": 31 + "px",
      },
      style2: {
        display: "inline-block",
        "font-size": 16 + "px",
        "text-align": "center",
        width: "60%",
      },
      yarnList: [],
      msg: "",
      needleDisk: "",
      Syringe: "",
      needLeList: "",
      needleAlignmentValue: "",
      yarngauze: [],
      down: false,
      img_list: [],
      modCount: 0,
      isExport: false,
      renderText: "2." + this.$t("downloadPage.txt37"),
      selectList: [],
      // 导出类型 1：图片，2：PDF
      exportType: 1,
      pdfImgList: [
        {
          name: "2." + this.$t("downloadPage.txt37"),
          url: "",
        },
        /*  {
          name: "3." + this.$t("downloadPage.txt30"),
          url: "",
        },
        {
          name: "4." + this.$t("downloadPage.txt31"),
          url: "",
        }, */
      ],
      pdfShow: false,
      structureList: [],
      thStrList: [
        require("@/images/thcq.png"),
        require("@/images/thjq.png"),
        require("@/images/thfx.png"),
      ],
      HeadList: [
        this.$t("downloadPage.txt6"),
        this.$t("downloadPage.txt7"),
        this.$t("downloadPage.txt8"),
        this.$t("downloadPage.txt9"),
        this.$t("downloadPage.txt10"),
      ],
      fabricInfoData: [],
      thumbnailImgurl: "",
      materialQuality: [
        this.$t("pup.txt24"),
        this.$t("pup.txt25"),
        this.$t("pup.txt26"),
        this.$t("pup.txt27"),
        this.$t("pup.txt28"),
        this.$t("pup.txt29"),
        this.$t("pup.txt30"),
        this.$t("pup.txt31"),
        this.$t("pup.txt32"),
        this.$t("pup.txt33"),
        this.$t("pup.txt34"),
        this.$t("pup.txt35"),
        this.$t("pup.txt36"),
        this.$t("pup.txt37"),
        this.$t("pup.txt38"),
      ], //成分数据
      structureImg: "",
      structurecolor: [],
      socksBodyYarnCount: "", //袜身颜色数量
    };
  },
  components: {
    FixNao,
    oneSideimg,
    doubleSideimg,
    Render,
    jacquardWeaveMeanWork,
  },
  computed: {},
  async mounted() {
    const res = await getClothDetail({ draperyId: this.$route.query.id });
    if (res.code === 0) {
      const { draperInfo, designConfig, designResultPath, roadNum } =
        res.data.draperInfoDetail;
      let clothDetailinfo = res.data.draperInfoDetail;
      this.machineName = res.data.draperInfoDetail.machineName;
      this.clothImg = designResultPath;
      this.structureImg = clothDetailinfo.designConfig.StructureImg;
      this.structurecolor = clothDetailinfo.designConfig.StructureImgColorList;
      let loader = new THREE.TextureLoader();
      let that = this;
      loader.load(this.clothImg.colorFront, function (tex) {
        let color = tex;
        that.clothWidth = parseInt(2000 / color.image.width);
        if (color.image.width > color.image.height) {
          that.sizeRepeatX = (color.image.height / color.image.width) * 1;
        } else {
          that.sizeRepeatX = (color.image.width / color.image.height) * 1;
        }
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt13"),
        con: clothDetailinfo.draperInfo.productCode,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt14"),
        con: clothDetailinfo.draperInfo.productName,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt15"),
        con: clothDetailinfo.draperInfo.craftRequire,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt16"),
        con: clothDetailinfo.draperInfo.ribWidth,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt17"),
        con: clothDetailinfo.draperInfo.ribHeight,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt18"),
        con: clothDetailinfo.draperInfo.coamingLength,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt19"),
        con: clothDetailinfo.draperInfo.coamingCrossWidth,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt20"),
        con: clothDetailinfo.draperInfo.doffingLength,
      });
      this.fabricInfoData.push({
        title: this.$t("historyDetail.txt21"),
        con: clothDetailinfo.draperInfo.doffingCrossWidth,
      });
      let arr = [];
      if (this.fabricInfoData.length % 5 != 0) {
        for (var i = 0; i < 5 - (this.fabricInfoData.length % 5); i++) {
          arr.push({
            title: "",
            con: "",
          });
        }
        this.fabricInfoData.push(...arr);
      }
      /* this.renderThumbnail(
        designConfig.meanWork.data.reverse(),
        designConfig.copyYarnList
      ); */
      /* designConfig.gauzeList.forEach((item, index) => {
        designConfig.copyYarnList.forEach((el) => {
          if (el.hexCode == item.yarnColor) {
            item.nr = el.nr;
          }
        });
        this.yarnList.push(item);
      }); */
      let yarnItem;
      clothDetailinfo.designConfig.copyYarnList.forEach((el) => {
        yarnItem = {
          nr: "",
          rawMaterials: "",
          yarnCount: "",
          yarnColor: "",
          typeName: "",
        };
        yarnItem.nr = el.nr;
        yarnItem.yarnColor = el.hexCode;
        el.component.data.forEach((elItem) => {
          yarnItem.rawMaterials += `${elItem.proportion}%${
            this.materialQuality[elItem.componentNum]
          }`;
        });
        yarnItem.yarnCount =
          el.YarnCount.num + (el.YarnCount.unit == 1 ? "D" : "S");
        this.yarnList.push(yarnItem);
        yarnItem.typeName = this.$t("historyDetail.txt66");
      });
      this.socksBodyYarnCount =
        clothDetailinfo.designConfig.copyYarnList.length;
      clothDetailinfo.designConfig.positionColorList.forEach((item, index) => {
        yarnItem = {
          nr: "",
          rawMaterials: "",
          yarnCount: "",
          yarnColor: "",
          typeName: "",
        };
        if (index < clothDetailinfo.designConfig.positionColorList.length - 1) {
          yarnItem.nr = item.nr;
          yarnItem.yarnColor = item.hexCode;
          item.component.data.forEach((elItem) => {
            yarnItem.rawMaterials += `${elItem.proportion}%${
              this.materialQuality[elItem.componentNum]
            }`;
          });
          yarnItem.yarnCount =
            item.YarnCount.num + (item.YarnCount.unit == 1 ? "D" : "S");
          yarnItem.typeName = this.$t(`historyDetail.txt${index + 67}`);
          this.yarnList.push(yarnItem);
        }
      });
      this.yarngauze = designConfig.gauzeList;
      this.draperInfo = draperInfo;
      this.designId = draperInfo.designId;
      this.renderType = true;

      let YarnList = clothDetailinfo.designConfig.copyYarnList;
      let StructureImgColorList =
        clothDetailinfo.designConfig.StructureImgColorList;
      let addStructureList = clothDetailinfo.designConfig.addStructureList;
      let numArr = [];
      let structureList = [];
      let numType = false;
      StructureImgColorList.forEach((item, i) => {
        addStructureList.forEach((el, k) => {
          if (item.index != undefined) {
            if (item.index == el.index) {
              structureList.forEach((el, o) => {
                if (item.index == el.index) {
                  numType = true;
                }
              });
              if (!numType) {
                structureList.push({
                  keyList: el.Structure,
                  index: item.index,
                  colorList: [item.rgb],
                });
              }
              numType = false;
            }
          }
        });
      });
      /* YarnList.forEach((item, i) => {
        if (item.structure != undefined) {
          numArr.push(item.structure.num);
        }
      });
      let numIndex = 0;
      numArr.forEach((item, i) => {
        YarnList.forEach((items, j) => {
          if (items.structure != undefined) {
            if (item == items.structure.num) {
              structureList.forEach((el, o) => {
                if (item == el.num) {
                  numType = true;
                  numIndex = o;
                }
              });
              if (numType) {
                if (
                  structureList[numIndex].colorList.indexOf(items.hexCode) == -1
                ) {
                  structureList[numIndex].colorList.push(items.hexCode);
                }
              } else {
                structureList.push({
                  keyList: items.structure.structure,
                  num: items.structure.num,
                  colorList: [items.hexCode],
                });
              }
              numType = false;
            }
          }
        });
      }); */
      let colorList = [];
      if (structureList.length == 0 && this.structurecolor.length != 0) {
        this.structurecolor.forEach((item, index) => {
          colorList.push(item.rgb);
        });
        structureList.push({
          index: 0,
          keyList: [
            [1, 1],
            [1, 1],
          ],
          colorList: colorList,
        });
      }
      this.structureList = structureList;
      this.$nextTick(() => {
        this.$refs.renderComp.setMODEL({
          index: clothDetailinfo.designConfig.StructureIndex,
          active: 3,
        });
        // this.$refs.renderComp.setMODEL({ index: 5, active: 2 });
      });

      // this.selectList = JSON.parse(localStorage.getItem("selectList"));
      /*   setTimeout(() => {
        this.downloadPdf();
      }, 1000); */
      if (this.$route.query.type == 1) {
        this.exportType = 1;
        setTimeout(() => {
          this.downloadPdf();
        }, 1000);
      } else {
        this.exportType = 2;
        setTimeout(() => {
          // this.pdfDownload();
          /*  if (this.roadNumber > 32 && this.machineName != "单面大圆机") {
            this.setMeanWorkImg();
          } */
        }, 1000);
      }
    }
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // return [row.nameIndex, 1]
      if (columnIndex === 0) {
        //用于设置要合并的列
        // console.log(rowIndex, this.socksBodyYarnCount);
        if (rowIndex < this.socksBodyYarnCount) {
          if (rowIndex % this.socksBodyYarnCount == 0) {
            // console.log(rowIndex);
            return {
              rowspan: this.socksBodyYarnCount,
              colspan: 1,
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0,
            };
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          };
        }
      }
    },
    getFrontUrlMap() {
      return {
        "background-image": 'url("' + this.clothImg.colorFront + '")',
        // width: "100%",
        // height: "98%", parseInt(4096 /img.width.width)
        "background-size": this.sizeRepeatX * 100 + "%",
        // "background-size": 100 + "%",
      };
    },
    getBackUrlMap() {
      return {
        "background-image": 'url("' + this.clothImg.colorBack + '")',
        // width: "100%",
        // height: "98%", parseInt(4096 /img.width.width)
        "background-size": 100 / this.clothWidth + "%",
      };
    },
    //生成缩略图
    renderThumbnail(data, colorList) {
      let canvas = document.createElement("canvas");
      let context = canvas.getContext("2d");
      canvas.width = data[0].length;
      canvas.height = data.length;
      var newImgData = context.createImageData(canvas.width, canvas.height);
      let img_index = 0;
      data.forEach((el) => {
        el.forEach((item, index) => {
          let colorIndex = 0;
          if (item > 10) {
            colorIndex = parseInt(item / 10);
          } else {
            colorIndex = 0;
          }
          let str = colorList[colorIndex].rgb.split(",");
          newImgData.data[img_index + 0] = str[0];
          newImgData.data[img_index + 1] = str[1];
          newImgData.data[img_index + 2] = str[2];
          newImgData.data[img_index + 3] = 255;
          img_index += 4;
        });
      });
      context.putImageData(newImgData, 0, 0);
      this.thumbnailImgurl = canvas.toDataURL("image/png");
    },
    async downloadPdf() {
      if (!this.down) {
        this.down = true;
        let jacquardWeaveFront,
          basicImg,
          yarnImg,
          fabricInfoImg,
          structureSplice;

        // 基本信息
        // if (this.selectList[0].check) {
        await html2canvas(this.$refs.basicInformation, {
          scale: 4,
          allowTaint: true,
          taintTest: false,
          width: this.$refs.basicInformation.offsetWidth + 10,
        }).then((canvas) => {
          // 转成图片，生成图片地址
          basicImg = canvas.toDataURL("image/png");
          this.img_list.push({
            name: `${this.draperInfo.productCode}-${this.$t(
              "downloadPage.txt32"
            )}`,
            baseImg: basicImg,
          });
        });
        // 纱线信息
        await html2canvas(this.$refs.yarnInfo, {
          scale: 4,
          allowTaint: true,
          taintTest: false,
          width: this.$refs.yarnInfo.offsetWidth + 10,
        }).then((canvas) => {
          // 转成图片，生成图片地址
          yarnImg = canvas.toDataURL("image/png");
          this.img_list.push({
            name: `${this.draperInfo.productCode}-${this.$t(
              "downloadPage.txt5"
            )}`,
            baseImg: yarnImg,
          });
        });
        // 面料信息
        await html2canvas(this.$refs.fabricInfo, {
          scale: 4,
          allowTaint: true,
          taintTest: false,
          width: this.$refs.fabricInfo.offsetWidth + 10,
        }).then((canvas) => {
          // 转成图片，生成图片地址
          fabricInfoImg = canvas.toDataURL("image/png");
          this.img_list.push({
            name: `${this.draperInfo.productCode}-${this.$t(
              "downloadPage.txt11"
            )}`,
            baseImg: fabricInfoImg,
          });
        });
        // 结构参数
        await html2canvas(this.$refs.structureSplice, {
          scale: 4,
          allowTaint: false,
          useCORS: true,
          taintTest: false,
          width: this.$refs.structureSplice.offsetWidth + 10,
        }).then((canvas) => {
          // 转成图片，生成图片地址
          structureSplice = canvas.toDataURL("image/png");
        });
        this.img_list.push({
          name: `${this.draperInfo.productCode}-${this.$t(
            "downloadPage.txt34"
          )}`,
          baseImg: structureSplice,
        });
        // }
        // 提花下载
        // 花型意匠图
        await html2canvas(this.$refs.jacquardWeaveFront, {
          scale: 4,
          allowTaint: false,
          useCORS: true,
          taintTest: false,
          width: this.$refs.jacquardWeaveFront.offsetWidth + 10,
        }).then((canvas) => {
          // 转成图片，生成图片地址
          jacquardWeaveFront = canvas.toDataURL("image/png");
          this.img_list.push({
            name: `${this.draperInfo.productCode}-${this.$t(
              "downloadPage.txt26"
            )}-${this.$t("downloadPage.txt27")}`,
            baseImg: jacquardWeaveFront,
          });
          if (this.isExport) {
            this.downloadZip();
          } else {
            this.isExport = true;
          }
        });
      }
    },
    setColor(Gauze, GauzeList, rodaNum) {
      //获取列表纱线
      var count = 0;
      Gauze.forEach((item, index) => {
        if (item != "" && index < rodaNum) {
          count++;
          GauzeList.forEach((items, indexs) => {
            if (item == items.id || item == items.yarnNumber) {
              if (this.yarnList.indexOf(items) < 0) {
                this.yarnList.push(items);
              }
            }
          });
        }
      });
      if (this.yarnList.length == 0 || count < rodaNum) {
        this.yarnList.push({
          yarnCount: "--",
          yarnLength: "--",
          yarnColor: "#FFFFFF",
          yarnName: "--",
          rawMaterials: this.$t("historyDetail.txt56"),
          singleFiberFineness: "--",
        });
      }
    },
    downloadZip() {
      let { img_list } = this;
      let blogTitle = `${this.draperInfo.productCode}图片`; // 下载的文件夹名称
      let zip = new JSZip();
      let imgs = zip.folder(blogTitle);
      let that = this;
      let baseList = [];
      for (let i = 0; i < img_list.length; i++) {
        let name = img_list[i].name; // 每张图片的名称
        let image = new Image(); // 解决跨域 Canvas 污染问题
        image.setAttribute("crossOrigin", "anonymous");
        image.src = img_list[i].baseImg;
        image.onload = function () {
          let canvas = document.createElement("canvas");
          canvas.width = image.width;
          canvas.height = image.height;
          let context = canvas.getContext("2d");
          context.drawImage(image, 0, 0, image.width, image.height);
          let url = canvas.toDataURL(); // 得到图片的base64编码数据 let url =
          canvas.toDataURL("image/png");
          baseList.push({ name: name, img: url.substring(22) });
          if (baseList.length === img_list.length) {
            if (baseList.length > 0) {
              for (let k = 0; k < baseList.length; k++) {
                imgs.file(baseList[k].name + ".png", baseList[k].img, {
                  base64: true,
                });
              }
              zip.generateAsync({ type: "blob" }).then(function (content) {
                FileSaver.saveAs(content, blogTitle + ".zip");
                that.$router.push({
                  path: `/history/thhistoryDetail/${that.$route.query.id}`,
                });
              });
            }
          }
        };
      }
      this.down = false;
    },
    async loadModelSuccess() {
      let mod3DImg, maleImg, femaleImg;

      let that = this;
      // 3D几何体
      // if (!this.machineName.includes("双面提花")) {
      // } else {
      //   if (!this.selectList[4].check) {
      //     this.isExport = true;
      //     return;
      //   }
      // }
      await html2canvas(that.$refs.mod3DImg, { scale: 4 }).then((canvas) => {
        // 转成图片，生成图片地址
        // if (that.modCount == 0) {
        mod3DImg = canvas.toDataURL("image/png");
        that.pdfImgList[0].url = mod3DImg;
        that.img_list.push({
          name: `${that.draperInfo.productCode}-${this.$t(
            "downloadPage.txt26"
          )}-${this.$t("downloadPage.txt37")}`,
          baseImg: mod3DImg,
        });
        that.modCount++;
        // that.renderText = "4." + this.$t("downloadPage.txt30");
        /*  that.$nextTick(() => {
            setTimeout(() => {
              that.$refs.renderComp.setMODEL({ index: 9, active: 2 });
            }, 1000);
          }); */
        /*  } else if (that.modCount == 1) {
          maleImg = canvas.toDataURL("image/png");
          that.pdfImgList[1].url = maleImg;
          that.img_list.push({
            name: `${that.draperInfo.productCode}-${this.$t(
              "downloadPage.txt26"
            )}-${this.$t("downloadPage.txt30")}`,
            baseImg: maleImg,
          });
          that.modCount++;
          that.renderText = "5." + this.$t("downloadPage.txt31");
          setTimeout(() => {
            that.$nextTick(() => {
              that.$refs.renderComp.setMODEL({ index: 5, active: 2 });
              // that.$refs.renderComp.setMODEL({ index: 18, active: 2 });
            });
          }, 1000);
        } else {
          femaleImg = canvas.toDataURL("image/png");
          that.pdfImgList[2].url = femaleImg;
          that.img_list.push({
            name: `${that.draperInfo.productCode}-${this.$t(
              "downloadPage.txt26"
            )}-${this.$t("downloadPage.txt31")}`,
            baseImg: femaleImg,
          });
          if (that.exportType == 1) {
            if (that.isExport) {
              that.downloadZip();
            } else {
              that.isExport = true;
            }
          } else {
            that.pdfShow = true;
            setTimeout(() => {
              that.pdfDownload();
            }, 1000);
          }
        } */
        if (that.exportType == 1) {
          if (that.isExport) {
            that.downloadZip();
          } else {
            that.isExport = true;
          }
        } else {
          that.pdfShow = true;
          setTimeout(() => {
            that.pdfDownload();
          }, 1000);
        }
      });
    },
    // 下载pdf
    async pdfDownload() {
      let _this = this;
      let myBox = this.$refs.downloadPDF; //获取ref里面的内容
      let PDF = new JsPDF("", "pt", "a4");
      let count = 0;
      // pdf首页，包含基本信息，纱线信息
      html2canvas(this.$refs.page1, {
        useCORS: true, //是否尝试使用CORS从服务器加载图像
        allowTaint: true,
        dpi: 300, //解决生产图片模糊
        scale: 3, //清晰度--放大倍数
      }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89; // 一页pdf显示html页面生成的canvas高度;
        let leftHeight = contentHeight; //未生成pdf的html页面高度
        let position = 0; //pdf页面偏移
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        // let imgWidth = 595.28
        // let imgWidth = 560.28; //宽度
        let imgWidth = 592.28; //宽度
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);

        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        PDF.addImage(pageData, "JPEG", 0, 20, imgWidth, imgHeight);
        PDF.addPage();
        // pdf第二页，包含坯布信息，成品要求，三角工艺信息，织针序列
        html2canvas(_this.$refs.page2, {
          useCORS: true, //是否尝试使用CORS从服务器加载图像
          allowTaint: true,
          dpi: 300, //解决生产图片模糊
          scale: 3, //清晰度--放大倍数
        }).then(function (canvas) {
          let contentWidth = canvas.width;
          let contentHeight = canvas.height;
          let pageHeight = (contentWidth / 592.28) * 841.89; // 一页pdf显示html页面生成的canvas高度;
          let leftHeight = contentHeight; //未生成pdf的html页面高度
          let position = 0; //pdf页面偏移
          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
          // let imgWidth = 595.28
          // let imgWidth = 560.28; //宽度
          let imgWidth = 592.28; //宽度
          let imgHeight = (592.28 / contentWidth) * contentHeight;
          let pageData = canvas.toDataURL("image/jpeg", 1.0);
          // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
          //当内容未超过pdf一页显示的范围，无需分页
          // PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
          // PDF.addPage();
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);

          PDF.addPage();

          // pdf第三页，包含意匠图或编织图
          html2canvas(_this.$refs.page3, {
            useCORS: true, //是否尝试使用CORS从服务器加载图像
            allowTaint: true,
            dpi: 300, //解决生产图片模糊
            scale: 3, //清晰度--放大倍数
          }).then(function (canvas) {
            let contentWidth = canvas.width;
            let contentHeight = canvas.height;
            let pageHeight = (contentWidth / 592.28) * 841.89; // 一页pdf显示html页面生成的canvas高度;
            let leftHeight = contentHeight; //未生成pdf的html页面高度
            let position = 0; //pdf页面偏移
            //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
            // let imgWidth = 595.28
            // let imgWidth = 560.28; //宽度
            let imgWidth = 592.28; //宽度
            let imgHeight = (592.28 / contentWidth) * contentHeight;
            let pageData = canvas.toDataURL("image/jpeg", 1.0);

            // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
            //当内容未超过pdf一页显示的范围，无需分页
            PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
            PDF.addPage();
            // pdf第三页，包含意匠图或编织图
            html2canvas(_this.$refs.page4, {
              useCORS: true, //是否尝试使用CORS从服务器加载图像
              allowTaint: true,
              dpi: 300, //解决生产图片模糊
              scale: 3, //清晰度--放大倍数
            }).then(function (canvas) {
              let contentWidth = canvas.width;
              let contentHeight = canvas.height;
              let pageHeight = (contentWidth / 592.28) * 841.89; // 一页pdf显示html页面生成的canvas高度;
              let leftHeight = contentHeight; //未生成pdf的html页面高度
              let position = 0; //pdf页面偏移
              //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
              // let imgWidth = 595.28
              // let imgWidth = 560.28; //宽度
              let imgWidth = 592.28; //宽度
              let imgHeight = (592.28 / contentWidth) * contentHeight;
              let pageData = canvas.toDataURL("image/jpeg", 1.0);

              // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
              //当内容未超过pdf一页显示的范围，无需分页
              PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
              if (_this.structureList.length > 0) {
                _this.handleHealthApi(PDF, _this, _this.structureList);
              } else {
                PDF.save("面料" + ".pdf"); //下载标题
                setTimeout(() => {
                  _this.$router.push({
                    path: `/history/thhistoryDetail/${_this.$route.query.id}`,
                  });
                }, 200);
              }
            });
          });
        });
      });
    },
    /**根据批次调健康管理接口 */
    async handleHealthApi(PDF, _this, structureList) {
      // 使用for await...of实现处理异步操作
      let index = -1;
      for await (let item of structureList) {
        index++;
        PDF.addPage();
        // pdf第四页，包含组织结构图
        let canvas = await html2canvas(_this.$refs[`page${index + 5}`][0], {
          useCORS: true, //是否尝试使用CORS从服务器加载图像
          allowTaint: true,
          dpi: 300, //解决生产图片模糊
          scale: 3, //清晰度--放大倍数
        });
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89; // 一页pdf显示html页面生成的canvas高度;
        let leftHeight = contentHeight; //未生成pdf的html页面高度
        let position = 0; //pdf页面偏移
        //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        // let imgWidth = 595.28
        // let imgWidth = 560.28; //宽度
        let imgWidth = 592.28; //宽度
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);

        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        //当内容未超过pdf一页显示的范围，无需分页
        PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);

        if (index == structureList.length - 1) {
          PDF.save("面料" + ".pdf"); //下载标题
          setTimeout(() => {
            _this.$router.push({
              path: `/history/thhistoryDetail/${_this.$route.query.id}`,
            });
          }, 200);
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "./THDownloadPage.less";
</style>