<template>
  <div class="doubleSideimg">
    <div class="doubleSideimgTop">
      <div class="title">编织图</div>
      <div class="label" v-if="pageType != 1">
        <div>
          <svg-icon icon-class="doubleSetCircle" class="svg"></svg-icon>
          <span>成圈</span>
        </div>
        <div>
          <svg-icon icon-class="doubleCircle"></svg-icon>
          <span>集圈</span>
        </div>
        <div style="border-right: 1px solid #dae1ed">
          <svg-icon icon-class="doubleEmerge" class="lastLable"></svg-icon>
          <span>浮线</span>
        </div>
      </div>
      <div class="resultlabel" v-if="pageType == 1">
        <div>
          <img src="@/images/jg1.png" alt="" />
          <span>成圈</span>
        </div>
        <div>
          <img src="@/images/jg2.png" alt="" />
          <span>集圈</span>
        </div>
        <div style="border-right: 1px solid rgba(0, 0, 0, 0.25)">
          <img src="@/images/jg3.png" alt="" class="jg3" />
          <span>浮线</span>
        </div>
      </div>
    </div>
    <div
      class="doubleSideimgCon"
      @mouseover="sethiddenScrollbar(true)"
      @mouseout="sethiddenScrollbar(false)"
    >
      <vue-custom-scrollbar
        :settings="settings"
        class="sroll"
        ref="Scroll"
        :style="CoypneedleDisk.length > 16 ? 'align-items: baseline;' : ''"
      >
        <div></div>
        <canvas class="myCanva" id="myCanvas" ref="myCanvas"></canvas>
      </vue-custom-scrollbar>
    </div>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  props: [
    "needleDisk",
    "Syringe",
    "needLeList",
    "roadNumber",
    "needleAlignmentValue",
    "reverseType",
    "pageType",
  ],
  components: {
    vueCustomScrollbar,
  },
  data() {
    return {
      t: "",
      CoypSyringe: [],
      CoypneedleDisk: [],
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      scrollType: 1,
      hiddenScrollbar: false,
    };
  },
  methods: {
    //监听滑入滑出
    sethiddenScrollbar(type) {
      if (type != this.hiddenScrollbar) {
        this.hiddenScrollbar = type;
        this.$emit("hiddenScrollbar", type);
      }
    },

    // 棉毛
    ifactive(frontArr, behindArr, i, j) {
      var number = "";
      if (i == frontArr.length - 1) {
        if (frontArr.length != 1) {
          if (
            frontArr[i - 1][j].active == 3 &&
            behindArr[i - 1][j].active != 3
          ) {
            number = 3 + frontArr[i][j].active + 2;
          } else {
            number = 3 + frontArr[i][j].active;
          }
        } else {
          number = 3 + frontArr[i][j].active;
        }
      } else {
        if (frontArr.length != 1) {
          if (i == 0) {
            if (
              frontArr[i + 1][j].active == 3 &&
              behindArr[i + 1][j].active != 3
            ) {
              number = 3 + frontArr[i][j].active + 2;
            } else {
              number = 3 + frontArr[i][j].active;
            }
          } else {
            if (
              frontArr[i - 1][j].active == 3 &&
              behindArr[i - 1][j].active != 3
            ) {
              if (
                frontArr[i + 1][j].active == 3 &&
                behindArr[i + 1][j].active != 3
              ) {
                number = 3 + frontArr[i][j].active + 2;
              } else {
                number = 3 + frontArr[i][j].active + 4;
              }
            } else {
              if (
                frontArr[i + 1][j].active == 3 &&
                behindArr[i + 1][j].active != 3
              ) {
                if (
                  frontArr[i - 1][j].active == 3 &&
                  behindArr[i - 1][j].active != 3
                ) {
                  number = 3 + frontArr[i][j].active + 2;
                } else {
                  number = 3 + frontArr[i][j].active + 6;
                }
              } else {
                number = 3 + frontArr[i][j].active;
              }
            }
          }
        } else {
          number = 3 + frontArr[i][j].active;
        }
      }
      return number;
    },
    cottonWool() {
      //修改渲染数据格式
      let { needleDisk, Syringe, needLeList, roadNumber } = this;
      var coypneedleDisk = JSON.parse(JSON.stringify(needleDisk)).reverse();
      var coypSyringe = JSON.parse(JSON.stringify(Syringe));
      var TopArr = [];
      var bottomArr = [];
      var clearTypeArr = JSON.parse(
        JSON.stringify(this.$store.state.index.clearType)
      );
      clearTypeArr.forEach((item, index) => {
        if (index < 3) {
          clearTypeArr[index] = 0;
        }
      });
      if (roadNumber != 12) {
        clearTypeArr[2] = 1;
      }
      needLeList.forEach((item, index) => {
        item.forEach((items, indexs) => {
          if (items.value != "") {
            if (clearTypeArr[1] == 0) {
              clearTypeArr[1] = 1;
            }
            if (indexs == 0) {
              TopArr[index] = [];
            } else {
              bottomArr[index] = [];
            }

            if (indexs == 0) {
              coypneedleDisk[items.value - 1].forEach((list, i) => {
                if (list.type) {
                  if (clearTypeArr[0] == 0) {
                    clearTypeArr[0] = 1;
                  }
                  TopArr[index].push({
                    active: list.active,
                    imgType: "",
                  });
                  if (item[indexs + 1].value == "") {
                    if (bottomArr[index] == undefined) {
                      bottomArr[index] = [];
                    }
                    bottomArr[index].push({
                      active: "",
                      imgType: "",
                    });
                  }
                }
              });
            } else {
              coypSyringe[items.value - 1].forEach((list, i) => {
                if (list.type) {
                  if (clearTypeArr[0] == 0) {
                    clearTypeArr[0] = 1;
                  }
                  bottomArr[index].push({
                    active: list.active,
                    imgType: "",
                  });
                  if (item[indexs - 1].value == "") {
                    if (TopArr[index] == undefined) {
                      TopArr[index] = [];
                    }
                    TopArr[index].push({
                      active: "",
                      imgType: "",
                    });
                  }
                }
              });
            }
          }
        });
      });
      if (TopArr.length == 0 && bottomArr.length == 0) {
        //织针为空清除画布
        var canvas = document.getElementById("myCanvas");
        var context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        return;
      }
      if (TopArr.length < 4) {
        if (TopArr.length == 1) {
          for (var i = 0; i < 3; i++) {
            TopArr.push(TopArr[0]);
            bottomArr.push(bottomArr[0]);
          }
        } else {
          var arrLength = JSON.parse(JSON.stringify(TopArr.length));
          var copyArrTop = JSON.parse(JSON.stringify(TopArr));
          var copyArrBottom = JSON.parse(JSON.stringify(bottomArr));

          for (var j = 0; j < arrLength * 2; j++) {
            if (TopArr[j] == undefined) {
              TopArr.push(copyArrTop[j - arrLength]);
              bottomArr.push(copyArrBottom[j - arrLength]);
            }
          }
        }
      }
      this.$emit("limit", 0);

      for (var i = 0; i < TopArr.length; i++) {
        if (TopArr[i] != undefined) {
          for (var j = 0; j < TopArr[i].length; j++) {
            if (TopArr[i][j].active != "") {
              if (TopArr[i][j].active != 3) {
                if (bottomArr[i][j].active != "") {
                  if (bottomArr[i][j].active == 3) {
                    TopArr[i][j].imgType = this.ifactive(
                      TopArr,
                      bottomArr,
                      i,
                      j
                    );
                    bottomArr[i][j].imgType = 3;
                  } else {
                    TopArr[i][j].imgType = TopArr[i][j].active;
                    bottomArr[i][j].imgType = bottomArr[i][j].active;
                    this.$emit("limit", 1);
                  }
                } else {
                  TopArr[i][j].imgType = TopArr[i][j].active;
                  this.$emit("limit", 1);
                }
              } else {
                if (bottomArr[i][j].active != "") {
                  if (bottomArr[i][j].active == 3) {
                    this.$emit("limit", 1);
                    bottomArr[i][j].imgType = 3;
                  } else {
                    bottomArr[i][j].imgType = this.ifactive(
                      bottomArr,
                      TopArr,
                      i,
                      j
                    );
                  }
                }
                TopArr[i][j].imgType = 3;
              }
            } else {
              if (bottomArr[i][j].active != "") {
                bottomArr[i][j].imgType = bottomArr[i][j].active;
              }
            }
          }
        }
      }
      this.CoypneedleDisk = TopArr;
      this.CoypSyringe = bottomArr;
      this.$store.commit("index/setClearType", clearTypeArr);
      if (this.needleAlignmentValue == "mianmao") {
        if (this.CoypneedleDisk.length != 0) {
          this.setCanvasData(); //棉毛1
        }
      } else {
        if (this.CoypneedleDisk.length != 0) {
          this.Rib(); //罗纹
        }
      }
    },
    setCanvasData() {
      //棉毛2
      this.$nextTick(() => {
        var canvas = document.getElementById("myCanvas");
        var context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);
        canvas.setAttribute("width", this.CoypneedleDisk.length * 24 + 28);
        canvas.setAttribute("height", this.CoypneedleDisk[0].length * 50);
        if (this.scrollType == 1) {
          this.scrollType = 2;
          this.$nextTick(() => {
            this.$refs.Scroll.$el.scrollTop =
              this.CoypneedleDisk[0].length * 50;
          });
        }
        for (var i = this.roadNumber; i > 0; i--) {
          context.font = "12pt Calibri";
          context.strokeStyle = "rgb(0,0,0)";
          context.fillText(
            "#" + i,
            0,
            this.CoypneedleDisk[0].length * 50 - (i * 50 - 26)
          );
        }
        this.CoypneedleDisk.forEach((item, index) => {
          item.forEach((items, indexs) => {
            var topData = {
              active:
                this.CoypneedleDisk[index][
                  this.CoypneedleDisk[index].length - 1 - indexs
                ].active,
              imgType:
                this.CoypneedleDisk[index][
                  this.CoypneedleDisk[index].length - 1 - indexs
                ].imgType,
              index,
              indexs,
              arrtype: 1,
              context,
            };
            var bottomData = {
              active:
                this.CoypSyringe[index][
                  this.CoypSyringe[index].length - 1 - indexs
                ].active,
              imgType:
                this.CoypSyringe[index][
                  this.CoypSyringe[index].length - 1 - indexs
                ].imgType,
              index,
              indexs,
              arrtype: 2,
              context,
            };
            this.canvasImgmm(topData);
            this.canvasImgmm(bottomData);
          });
        });
      });
    },

    canvasImgmm({ active, imgType, index, indexs, arrtype, context }) {
      //棉毛3
      var img = new Image();
      var that = this;
      if (active != "") {
        if (arrtype == 1) {
          if (imgType == 1 || imgType == 2 || imgType == 3) {
            if (imgType == 3) {
              if (
                this.CoypSyringe[index][
                  this.CoypSyringe[index].length - 1 - indexs
                ].imgType == 3
              ) {
                img.src = require(`@/images/doubelTopred${imgType}@2x.png`);
              } else {
                img.src = require(`@/images/doubleTop${imgType}@2x.png`);
              }
            } else {
              img.src = require(`@/images/doubelTopred${imgType}@2x.png`);
            }
          } else {
            img.src = require(`@/images/doubleTop${
              imgType >= 6 ? active : imgType
            }@2x.png`);
          }
          img.onload = function () {
            if (imgType >= 6) {
              if (imgType > 5 && imgType < 8) {
                //左侧
                if (index == 0) {
                  context.beginPath();
                  if (active == 1) {
                    //大成圈左侧起点
                    context.moveTo(index * 22 + 46, indexs * 50 + 19);
                  } else {
                    //大集圈左侧起点
                    context.moveTo(index * 22 + 46, indexs * 50 + 18.6);
                  }
                  context.lineTo(index * 22 + 39, indexs * 50 + 25);
                  context.lineWidth = 1;
                  context.strokeStyle = "#000";
                  context.fill();
                  context.stroke();
                }

                if (that.CoypneedleDisk.length - 1 == index) {
                  //大成圈 集圈左侧是当行最后一个时终点
                  context.beginPath();
                  if (active == 1) {
                    //大成圈右侧起点
                    context.moveTo(index * 22 + 50, indexs * 50 + 19);
                  } else {
                    //大集圈右侧起点
                    context.moveTo(index * 22 + 50, indexs * 50 + 18.6);
                  }
                  context.lineTo(index * 22 + 58, indexs * 50 + 25);
                  context.lineWidth = 1;
                  context.strokeStyle = "#000";
                  context.fill();
                  context.stroke();
                }
              } else {
                if (imgType > 7 && imgType < 10) {
                  context.beginPath();
                  if (active == 1) {
                    //大成圈右侧起点
                    context.moveTo(index * 22 + 50, indexs * 50 + 19);
                    context.lineTo(index * 22 + 60, indexs * 50 + 19.2);
                  } else {
                    //大集圈右侧起点
                    context.moveTo(index * 22 + 51, indexs * 50 + 19.5);
                    context.lineTo(index * 22 + 60, indexs * 50 + 19.8);
                  }
                  context.lineWidth = 1;
                  context.strokeStyle = "#000";
                  context.fill();
                  context.stroke();
                } else {
                  context.beginPath();
                  if (active == 1) {
                    //大成圈左侧
                    context.moveTo(index * 22 + 48, indexs * 50 + 19);
                    context.lineTo(index * 22 + 38, indexs * 50 + 19.2);
                  } else {
                    //大集圈左侧
                    context.moveTo(index * 22 + 45, indexs * 50 + 19.5);
                    context.lineTo(index * 22 + 38, indexs * 50 + 19.8);
                  }
                  context.lineWidth = 1;
                  context.strokeStyle = "#000";
                  context.fill();
                  context.stroke();
                }
              }
            }
            if (imgType == 4 || imgType == 5) {
              context.drawImage(img, index * 22 + 36, indexs * 50, 24, 24);
            } else {
              context.drawImage(img, index * 22 + 36, indexs * 50, 24, 24);
            }
          };
        } else {
          if (imgType == 1 || imgType == 2 || imgType == 3) {
            if (imgType == 3) {
              if (
                this.CoypneedleDisk[index][
                  this.CoypneedleDisk[index].length - 1 - indexs
                ].imgType == 3
              ) {
                img.src = require(`@/images/doubelbottomred${imgType}@2x.png`);
              } else {
                img.src = require(`@/images/doubelbottom${imgType}@2x.png`);
              }
            } else {
              img.src = require(`@/images/doubelbottomred${imgType}@2x.png`);
            }
          } else {
            img.src = require(`@/images/doubelbottom${
              imgType >= 6 ? active : imgType
            }@2x.png`);
          }

          if (indexs == 2) {
          }
          img.onload = function () {
            if (imgType >= 6) {
              if (imgType > 5 && imgType < 8) {
                //大集圈 成圈
                //左侧
                context.beginPath();
                if (active == 1) {
                  //大成圈左侧起点
                  context.moveTo(index * 22 + 46, indexs * 50 + 30);
                } else {
                  //大集圈左侧起点
                  context.moveTo(index * 22 + 44.5, indexs * 50 + 30);
                }
                if (index == 0) {
                  //大集圈 成圈左侧是当行第一时终点
                  context.lineTo(index * 22 + 38, indexs * 50 + 24.5);
                } else {
                  //大集圈 成圈左侧不是当行第一时终点
                  context.lineTo(index * 22 + 28, indexs * 50 + 18.3);
                }
                context.lineWidth = 1;
                context.strokeStyle = "#000";
                context.fill();
                context.stroke();

                //右侧
                context.beginPath();
                //大成圈 集圈右侧起点
                context.moveTo(index * 22 + 50 + active, indexs * 50 + 30);
                if (that.CoypSyringe.length - 1 == index) {
                  //大成圈 集圈右侧是当行最后一个时终点
                  context.lineTo(index * 22 + 58, indexs * 50 + 24);
                } else {
                  //大成圈 集圈右侧不是是当行最后一个时终点
                  context.lineTo(index * 22 + 68, indexs * 50 + 18.6);
                }
                context.lineWidth = 1;
                context.strokeStyle = "#000";
                context.fill();
                context.stroke();
              } else if (imgType == 8 || imgType == 9) {
                //左侧
                context.beginPath();
                if (active == 1) {
                  //大成圈左侧起点
                  context.moveTo(index * 22 + 46, indexs * 50 + 30);
                } else {
                  //大集圈左侧起点
                  context.moveTo(index * 22 + 44.5, indexs * 50 + 30);
                }
                context.lineTo(index * 22 + 28, indexs * 50 + 18.4);
                context.lineWidth = 1;
                context.strokeStyle = "#000";
                context.fill();
                context.stroke();

                //右侧
                context.beginPath();
                if (active == 1) {
                  //大成圈右侧
                  context.moveTo(index * 22 + 49, indexs * 50 + 29);
                  context.lineTo(index * 22 + 61, indexs * 50 + 28.5);
                } else {
                  //大集圈右侧
                  context.moveTo(index * 22 + 52, indexs * 50 + 30);
                  context.lineTo(index * 22 + 61, indexs * 50 + 29.5);
                }
                context.lineWidth = 1;
                context.strokeStyle = "#000";
                context.stroke();
              } else if (imgType == 10 || imgType == 11) {
                //左侧
                context.beginPath();
                if (active == 1) {
                  //大成圈左侧起点
                  context.moveTo(index * 22 + 48, indexs * 50 + 29);
                  context.lineTo(index * 22 + 35, indexs * 50 + 28.5);
                } else {
                  //大集圈左侧起点
                  context.moveTo(index * 22 + 45, indexs * 50 + 30);
                  context.lineTo(index * 22 + 35, indexs * 50 + 30);
                }
                context.lineWidth = 1;
                context.strokeStyle = "#000";
                context.fill();
                context.stroke();

                //右侧
                context.beginPath();
                //大成圈 集圈右侧起点
                context.moveTo(index * 22 + 50 + active, indexs * 50 + 30);
                if (that.CoypSyringe.length - 1 == index) {
                  //大成圈 集圈右侧是当行最后一个时终点
                  context.lineTo(index * 22 + 58, indexs * 50 + 24);
                } else {
                  //大成圈 集圈右侧不是是当行最后一个时终点
                  context.lineTo(index * 22 + 68, indexs * 50 + 18.6);
                }
                context.lineWidth = 1;
                context.strokeStyle = "#000";
                context.fill();
                context.stroke();
              }
            }

            if (active == 1 || (imgType < 6 && active != 2)) {
              context.drawImage(img, index * 22 + 36, indexs * 50 + 24, 24, 24);
            } else {
              if (imgType < 6) {
                if (imgType == 5) {
                  context.drawImage(
                    img,
                    index * 22 + 36,
                    indexs * 50 + 24,
                    24,
                    24
                  );
                } else {
                  context.drawImage(
                    img,
                    index * 22 + 36,
                    indexs * 50 + 25,
                    24,
                    24
                  );
                }
              } else {
                context.drawImage(
                  img,
                  index * 22 + 36,
                  indexs * 50 + 26,
                  24,
                  24
                );
              }
            }
          };
        }
      }
    },

    getbothSidesindex(arr, index, type, endArr) {
      //判断左右两边最近不是浮线的index 罗纹
      if (type == 0) {
        var topNumber = undefined;
        for (var i = index; i < arr.length; i++) {
          if (arr[i].active != "") {
            if (arr[i].active != 3) {
              topNumber = i;
              return topNumber;
            }
          }
        }
        return topNumber;
      } else {
        var indexNumber = undefined;
        for (var i = 0; i < index; i++) {
          if (arr[i].active != "") {
            if (arr[i].active != 3) {
              indexNumber = i;
            }
          }
        }
        return indexNumber;
      }
    },

    ifIndexSize(topIndex, bottomIndex, type) {
      //判断一侧针盘还是针筒最近的index 罗纹
      if (topIndex != undefined) {
        if (bottomIndex != undefined) {
          if (topIndex > bottomIndex) {
            if (type == 1) {
              return {
                type: 1,
                index: bottomIndex,
              };
            } else {
              return {
                type: 0,
                index: topIndex,
              };
            }
          } else if (topIndex == bottomIndex) {
            if (type == 1) {
              return {
                type: 0,
                index: topIndex,
              };
            } else {
              return {
                type: 1,
                index: bottomIndex,
              };
            }
          } else {
            if (type == 1) {
              return {
                type: 0,
                index: topIndex,
              };
            } else {
              return {
                type: 1,
                index: bottomIndex,
              };
            }
          }
        } else {
          return {
            type: 0,
            index: topIndex,
          };
        }
      } else {
        if (bottomIndex == undefined) {
          return "";
        } else {
          return {
            type: 1,
            index: bottomIndex,
          };
        }
      }
    },

    ifFloatingLine(index, arr, endArr) {
      //判断是否有全是浮线相连 罗纹
      var arrIndex = [];
      var fristIndex = index - 3 <= 0 ? 0 : index - 3;
      var lastIndex = index + 3 >= arr.length - 1 ? arr.length - 1 : index + 3;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].active == 3 && endArr[i].active == 3) {
          arrIndex.push(i);
        } else {
          arrIndex = [];
        }
      }
      return arrIndex;
    },

    canvasImglw(arr, arrType, endArr) {
      //生成canvas 罗纹
      var canvas = document.getElementById("myCanvas");
      var context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      canvas.setAttribute("width", arr[0].length * 30 + 54);
      canvas.setAttribute("height", arr.length * 54);
      if (this.scrollType == 1) {
        this.scrollType = 2;
        this.$nextTick(() => {
          this.$refs.Scroll.$el.scrollTop = this.CoypneedleDisk[0].length * 50;
        });
      }
      for (var i = this.roadNumber; i > 0; i--) {
        context.font = "12pt Calibri";
        context.strokeStyle = "rgb(0,0,0)";
        context.fillText(
          `#${Math.abs(i - this.roadNumber) + 1}`,
          0,
          i * 54 - 28
        );
      }
      if (arrType == 1) {
        //针盘
        arr.reverse();
        endArr.reverse();
        arr.forEach((item, index) => {
          item.forEach((items, indexs) => {
            var imgType = "";
            if (items.active != "") {
              var img = new Image();
              //判断active使用那个图片
              if (items.redType == 1 && endArr[index][indexs].redType == 1) {
                img.src = require(`@/images/doubelTopred3@2x.png`);
              } else {
                if (
                  items.ringhtLength != undefined ||
                  items.leftLength != undefined
                ) {
                  if (items.active == 2) {
                    if (items.ringhtLength.type == 0) {
                      img.src = require(`@/images/top2.png`);
                      imgType = 2;
                    } else {
                      if (items.imgActive == 2) {
                        img.src = require(`@/images/top2.png`);
                        imgType = 2;
                      } else {
                        img.src = require(`@/images/doubeltop2.png`);
                      }
                    }
                  } else {
                    img.src = require(`@/images/doubleTop${items.active}@2x.png`);
                  }
                } else {
                  img.src = require(`@/images/doubleTop${items.active}@2x.png`);
                }
              }

              img.onload = function () {
                //图片下载完成添加进canvas

                if (items.active != 3) {
                  //左侧
                  if (items.leftLength == "a") {
                    //为每一列第一个时
                    if (endArr[index][0].active != "") {
                      if (items.active == 1) {
                        //成圈
                        context.beginPath();
                        if (endArr[index][0].active == 3) {
                          context.moveTo(indexs * 20 + 44, index * 54 + 20);
                          context.lineTo(indexs * 20 + 30, index * 54 + 20);
                        } else {
                          context.moveTo(indexs * 20 + 40, index * 54 + 18);
                          context.lineTo(indexs * 22 + 36, index * 54 + 26);
                        }
                        context.lineWidth = 1;
                        context.strokeStyle = "#000";
                        context.fill();
                        context.stroke();
                      } else {
                        //集圈
                        //判断是否有突出的角
                        if (endArr[index][0].active == 3) {
                          //有角
                          context.beginPath();
                          context.moveTo(indexs * 20 + 41, index * 54 + 17);
                          context.lineTo(indexs * 20 + 38, index * 54 + 20);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();

                          context.beginPath();
                          context.moveTo(indexs * 20 + 38, index * 54 + 20);
                          context.lineTo(indexs * 20 + 28, index * 54 + 20);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                          // if (imgType == 2) {
                          // }
                        } else {
                          //没角
                          context.beginPath();
                          context.moveTo(indexs * 20 + 41.5, index * 54 + 20);
                          context.lineTo(indexs * 20 + 34, index * 54 + 26);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    }
                  } else {
                    //为每一列左侧样式
                    if (items.active == 1) {
                      //成圈
                      if (items.leftLength != undefined) {
                        if (items.leftLength.type == 0) {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 42.5, index * 54 + 20);
                          if (arr[index][items.leftLength.index] != undefined) {
                            if (
                              arr[index][items.leftLength.index].imgActive == 2
                            ) {
                              context.lineTo(
                                indexs * 30 +
                                  44 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 20
                              );
                            } else {
                              context.lineTo(
                                indexs * 30 +
                                  42 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 20
                              );
                            }
                          } else {
                            context.lineTo(
                              indexs * 30 +
                                42 -
                                (indexs - items.leftLength.index) * 30,
                              index * 54 + 20
                            );
                          }
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        } else {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 41, index * 54 + 18);
                          if (
                            endArr[index][items.leftLength.index] != undefined
                          ) {
                            if (
                              endArr[index][items.leftLength.index].active == 1
                            ) {
                              context.lineTo(
                                indexs * 30 +
                                  60 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 30
                              );
                            } else {
                              if (
                                endArr[index][items.leftLength.index]
                                  .imgActive == 2
                              ) {
                                context.lineTo(
                                  indexs * 30 +
                                    57 -
                                    (indexs - items.leftLength.index) * 30,
                                  index * 54 + 32
                                );
                              } else {
                                context.lineTo(
                                  indexs * 30 +
                                    56.5 -
                                    (indexs - items.leftLength.index) * 30,
                                  index * 54 + 28
                                );
                              }
                            }
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();
                          }
                        }
                      }
                    } else {
                      //集圈
                      if (items.leftLength != undefined) {
                        if (items.leftLength.type == 0) {
                          //判断是连接上面
                          context.beginPath();
                          context.moveTo(indexs * 30 + 42, index * 54 + 17);
                          context.lineTo(indexs * 30 + 38, index * 54 + 20);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();

                          context.beginPath();
                          context.moveTo(indexs * 30 + 39, index * 54 + 20);
                          if (arr[index][indexs - 1] != undefined) {
                            if (
                              arr[index][indexs - 1].active != "" &&
                              arr[index][indexs - 1].active == 1
                            ) {
                              context.lineTo(
                                indexs * 30 +
                                  42 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 20
                              );
                            } else {
                              context.lineTo(
                                indexs * 30 +
                                  45 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 20
                              );
                            }
                          } else {
                            context.lineTo(
                              indexs * 30 +
                                45 -
                                (indexs - items.leftLength.index) * 30,
                              index * 54 + 20
                            );
                          }
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        } else {
                          //判断是连接下面
                          context.beginPath();
                          if (imgType == 2) {
                            context.moveTo(indexs * 30 + 42, index * 54 + 16);
                          } else {
                            context.moveTo(indexs * 30 + 41, index * 54 + 20);
                          }
                          if (
                            endArr[index][items.leftLength.index] != undefined
                          ) {
                            if (
                              endArr[index][items.leftLength.index].active == 1
                            ) {
                              context.lineTo(
                                indexs * 30 +
                                  60 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 30
                              );
                            } else {
                              if (
                                endArr[index][items.leftLength.index]
                                  .imgActive == 2
                              ) {
                                context.lineTo(
                                  indexs * 30 +
                                    57 -
                                    (indexs - items.leftLength.index) * 30,
                                  index * 54 + 32
                                );
                              } else {
                                context.lineTo(
                                  indexs * 30 +
                                    56.5 -
                                    (indexs - items.leftLength.index) * 30,
                                  index * 54 + 28
                                );
                              }
                            }
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();
                          }
                        }
                      }
                    }
                  }

                  //右侧
                  if (items.active == 1) {
                    //成圈
                    if (indexs == item.length - 1) {
                      //是否有最后一个
                      if (endArr[index][indexs].active != "") {
                        if (endArr[index][indexs].active == 3) {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 42, index * 54 + 20);
                          context.lineTo(indexs * 30 + 56, index * 54 + 20);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        } else {
                          if (endArr[index][indexs].active == 1) {
                            context.beginPath();
                            context.moveTo(indexs * 30 + 44, index * 54 + 18);
                            context.lineTo(indexs * 30 + 56, index * 54 + 30);
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();
                          } else {
                            context.beginPath();
                            context.moveTo(indexs * 30 + 44, index * 54 + 18);
                            context.lineTo(indexs * 30 + 57, index * 54 + 28);
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();
                          }
                        }
                      }
                    } else {
                      //不是最后一个处理
                      if (items.ringhtLength != undefined) {
                        if (items.ringhtLength.type == 1) {
                          context.beginPath();
                          if (items.ringhtLength != undefined) {
                            context.moveTo(indexs * 30 + 44, index * 54 + 18);
                          }
                          if (
                            endArr[index][items.ringhtLength.index].active == 1
                          ) {
                            context.lineTo(
                              indexs * 30 +
                                56 -
                                (indexs - items.ringhtLength.index) * 30,
                              index * 54 + 30
                            );
                          } else {
                            if (
                              endArr[index][items.ringhtLength.index]
                                .imgActive == 2
                            ) {
                              context.lineTo(
                                indexs * 30 +
                                  57 -
                                  (indexs - items.ringhtLength.index) * 30,
                                index * 54 + 32
                              );
                            } else {
                              context.lineTo(
                                indexs * 30 +
                                  57 -
                                  (indexs - items.ringhtLength.index) * 30,
                                index * 54 + 28
                              );
                            }
                          }
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        } else {
                        }
                      }
                    }
                  } else {
                    //集圈
                    if (indexs == item.length - 1) {
                      //判断是否是最后一个
                      if (endArr[index][indexs].active == "") {
                      } else {
                        if (endArr[index][indexs].active == 3) {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 41, index * 54 + 17);
                          context.lineTo(indexs * 30 + 46, index * 54 + 20);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                          context.beginPath();
                          context.moveTo(indexs * 30 + 46, index * 54 + 20);
                          context.lineTo(indexs * 30 + 56, index * 54 + 20);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        } else {
                          context.beginPath();
                          if (
                            items.imgActive == 2 ||
                            items.imgActive != undefined
                          ) {
                            context.moveTo(indexs * 30 + 42, index * 54 + 18);
                            context.lineTo(indexs * 30 + 45, index * 54 + 21);
                          } else {
                            context.moveTo(indexs * 30 + 42, index * 54 + 20);
                          }
                          if (endArr[index][indexs].active == 1) {
                            context.lineTo(indexs * 30 + 56, index * 54 + 30);
                          } else {
                            context.lineTo(indexs * 30 + 57.5, index * 54 + 28);
                          }
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    } else {
                      //不是最后一个处理
                      if (items.ringhtLength != undefined) {
                        if (items.ringhtLength.type == 1) {
                          context.beginPath();
                          if (
                            items.imgActive == 2 ||
                            items.imgActive != undefined
                          ) {
                            context.moveTo(indexs * 30 + 42, index * 54 + 17);
                          } else {
                            context.moveTo(indexs * 30 + 42, index * 54 + 20);
                          }

                          if (
                            endArr[index][items.ringhtLength.index].active == 1
                          ) {
                            context.lineTo(
                              indexs * 30 +
                                56 -
                                (indexs - items.ringhtLength.index) * 30,
                              index * 54 + 30
                            );
                          } else {
                            if (
                              endArr[index][items.ringhtLength.index]
                                .imgActive == 2
                            ) {
                              context.lineTo(
                                indexs * 30 +
                                  57 -
                                  (indexs - items.ringhtLength.index) * 30,
                                index * 54 + 32
                              );
                            } else {
                              context.lineTo(
                                indexs * 30 +
                                  57 -
                                  (indexs - items.ringhtLength.index) * 30,
                                index * 54 + 28
                              );
                            }
                          }
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        } else {
                          context.beginPath();
                          if (
                            items.imgActive == 2 ||
                            items.imgActive != undefined
                          ) {
                            context.moveTo(indexs * 30 + 42, index * 54 + 17);
                            context.lineTo(indexs * 30 + 45, index * 54 + 20);
                          }
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    }
                  }
                }
                context.drawImage(img, indexs * 30 + 30, index * 54, 24, 24);
              };
            }
          });
        });
      } else {
        //针筒
        arr.forEach((item, index) => {
          item.forEach((items, indexs) => {
            var imgType = "";
            if (items.active != "") {
              var img = new Image();
              //判断使用那张图片
              if (items.redType == 1 && endArr[index][indexs].redType == 1) {
                img.src = require(`@/images/doubelTopred3@2x.png`);
              } else {
                if (
                  items.ringhtLength != undefined ||
                  items.leftLength != undefined
                ) {
                  if (items.active == 2) {
                    if (items.imgActive == 2) {
                      img.src = require(`@/images/bottom2.png`);
                      imgType = 2;
                    } else {
                      if (items.ringhtLength.type == 1) {
                        img.src = require(`@/images/bottom2.png`);
                        imgType = 2;
                      } else if (items.leftLength.type == 1) {
                        img.src = require(`@/images/bottom2.png`);
                        imgType = 2;
                      } else {
                        img.src = require(`@/images/doubleBtoom2.png`);
                      }
                    }
                  } else {
                    img.src = require(`@/images/doubelbottom${items.active}@2x.png`);
                  }
                } else {
                  if (items.active == 2) {
                    img.src = require(`@/images/doubleBtoom2.png`);
                  } else {
                    img.src = require(`@/images/doubelbottom${items.active}@2x.png`);
                  }
                }
              }

              img.onload = function () {
                if (items.active != 3) {
                  //左侧
                  if (items.active == 1) {
                    //成圈
                    if (indexs == 0) {
                      //第一个针
                      if (endArr[index][0].active == "") {
                        context.beginPath();
                        context.moveTo(indexs * 30 + 56, index * 54 + 32);
                        context.lineTo(indexs * 30 + 46, index * 54 + 22);
                        context.lineWidth = 1;
                        context.strokeStyle = "#000";
                        context.fill();
                        context.stroke();
                      } else {
                        if (endArr[index][0].active == 3) {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 58, index * 54 + 28);
                          context.lineTo(indexs * 30 + 44, index * 54 + 28);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    } else {
                      //左侧针的样式处理
                      if (items.leftLength != undefined) {
                        if (items.leftLength != "") {
                          if (items.leftLength.type == 1) {
                            context.beginPath();
                            context.moveTo(indexs * 30 + 58, index * 54 + 28);
                            if (arr[index][indexs - 1] != undefined) {
                              if (
                                arr[index][indexs - 1].active != "" &&
                                arr[index][indexs - 1].active == 2
                              ) {
                                context.lineTo(
                                  indexs * 30 +
                                    (imgType == 2 ? 57 : 60) -
                                    (indexs - items.leftLength.index) * 30,
                                  index * 54 + 28
                                );
                              } else {
                                context.lineTo(
                                  indexs * 30 +
                                    58 -
                                    (indexs - items.leftLength.index) * 30,
                                  index * 54 + 28
                                );
                              }
                            } else {
                              context.lineTo(
                                indexs * 30 +
                                  58 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 28
                              );
                            }
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();
                          }
                        }
                      }
                    }
                  } else {
                    //集圈
                    if (indexs == 0) {
                      //第一根针
                      if (endArr[index][0].active == "") {
                        context.beginPath();
                        context.moveTo(indexs * 30 + 57, index * 54 + 30);
                        context.lineTo(indexs * 30 + 46, index * 54 + 22);
                        context.lineWidth = 1;
                        context.strokeStyle = "#000";
                        context.fill();
                        context.stroke();
                      } else {
                        if (endArr[index][0].active == 3) {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 57, index * 54 + 31);
                          context.lineTo(indexs * 30 + 54, index * 54 + 28);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();

                          context.beginPath();
                          context.moveTo(indexs * 30 + 54, index * 54 + 28);
                          context.lineTo(indexs * 30 + 44, index * 54 + 28);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    } else {
                      //每根针左侧处理
                      if (items.leftLength != undefined) {
                        if (items.leftLength != "") {
                          if (items.leftLength.type == 1) {
                            context.beginPath();
                            context.moveTo(indexs * 30 + 57, index * 54 + 31);
                            context.lineTo(indexs * 30 + 54, index * 54 + 28);
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();

                            context.beginPath();
                            context.moveTo(indexs * 30 + 54, index * 54 + 28);
                            if (arr[index][indexs - 1] != undefined) {
                              if (
                                arr[index][indexs - 1].active != "" &&
                                arr[index][indexs - 1].active == 1
                              ) {
                                context.lineTo(
                                  indexs * 30 +
                                    58 -
                                    (indexs - items.leftLength.index) * 30,
                                  index * 54 + 28
                                );
                              } else {
                                if (
                                  endArr[index][indexs - 1].ringhtLength !=
                                  undefined
                                ) {
                                  context.lineTo(
                                    indexs * 30 +
                                      60 -
                                      (indexs - items.leftLength.index) * 30,
                                    index * 54 + 28
                                  );
                                } else {
                                  context.lineTo(
                                    indexs * 30 +
                                      60 -
                                      (indexs - items.leftLength.index) * 30,
                                    index * 54 + 28
                                  );
                                }
                              }
                            } else {
                              context.lineTo(
                                indexs * 30 +
                                  60 -
                                  (indexs - items.leftLength.index) * 30,
                                index * 54 + 28
                              );
                            }
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();
                          }
                        }
                      }
                    }
                  }

                  //右侧
                  if (items.active == 1) {
                    //成圈
                    if (indexs == item.length - 1) {
                      if (endArr[index][indexs].active != "") {
                        if (endArr[index][indexs].active == 3) {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 58, index * 54 + 28);
                          context.lineTo(indexs * 30 + 68, index * 54 + 28);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        } else {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 60, index * 54 + 30);
                          context.lineTo(indexs * 30 + 66, index * 54 + 22);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    }
                  } else {
                    //集圈
                    if (indexs == item.length - 1) {
                      if (endArr[index][indexs].active != "") {
                        context.beginPath();
                        if (endArr[index][indexs].active == 3) {
                          context.beginPath();
                          if (items.imgActive == 2) {
                            context.beginPath();
                            context.moveTo(indexs * 30 + 58, index * 54 + 31);
                            context.lineTo(indexs * 30 + 60, index * 54 + 28);
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();

                            context.beginPath();
                            context.moveTo(indexs * 30 + 60, index * 54 + 28);
                            context.lineTo(indexs * 30 + 70, index * 54 + 28);
                            context.lineWidth = 1;
                            context.strokeStyle = "#000";
                            context.fill();
                            context.stroke();
                          }
                        } else {
                          context.beginPath();
                          if (items.imgActive == 2) {
                            context.moveTo(indexs * 30 + 58, index * 54 + 32);
                          } else {
                            context.moveTo(indexs * 30 + 57, index * 54 + 28);
                          }
                          context.lineTo(indexs * 30 + 66, index * 54 + 22);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    } else {
                      if (items.ringhtLength != undefined) {
                        if (items.ringhtLength.type == 1) {
                          context.beginPath();
                          context.moveTo(indexs * 30 + 58, index * 54 + 31);
                          context.lineTo(indexs * 30 + 60, index * 54 + 28);
                          context.lineWidth = 1;
                          context.strokeStyle = "#000";
                          context.fill();
                          context.stroke();
                        }
                      }
                    }
                  }
                }
                context.drawImage(
                  img,
                  indexs * 30 + 45,
                  index * 54 + 24,
                  24,
                  24
                );
              };
            }
          });
        });
      }
    },

    Rib() {
      //罗纹
      var topArr = JSON.parse(JSON.stringify(this.CoypneedleDisk));
      var bottomArr = JSON.parse(JSON.stringify(this.CoypSyringe));
      var coyptopArr = [];
      var coypbottomArr = [];
      this.$emit("limit", 0);
      for (var i = 0; i < this.roadNumber; i++) {
        coyptopArr.push([]);
        topArr.forEach((item, index) => {
          coyptopArr[i].push({
            active: topArr[index][i].active,
            imgType: topArr[index][i].imgType,
          });
        });
      }

      for (var i = 0; i < this.roadNumber; i++) {
        coypbottomArr.push([]);
        bottomArr.forEach((item, index) => {
          coypbottomArr[i].push({
            active: bottomArr[index][i].active,
            imgType: bottomArr[index][i].imgType,
          });
        });
      }

      coyptopArr.forEach((item, index) => {
        item.forEach((items, indexs) => {
          if (coypbottomArr[index][indexs].active != "" && items.active != "") {
            if (coypbottomArr[index][indexs].active != 3) {
              if (items.active != "") {
                coypbottomArr[index][indexs].leftLength = this.ifIndexSize(
                  this.getbothSidesindex(
                    item,
                    indexs + 1,
                    1,
                    coypbottomArr[index]
                  ),
                  this.getbothSidesindex(coypbottomArr[index], indexs, 1, item),
                  2
                );
              }
              coypbottomArr[index][indexs].ringhtLength = this.ifIndexSize(
                this.getbothSidesindex(
                  item,
                  indexs + 1,
                  0,
                  coypbottomArr[index]
                ),
                this.getbothSidesindex(
                  coypbottomArr[index],
                  indexs + 1,
                  0,
                  item
                ),
                1
              );

              if (
                coypbottomArr[index][indexs].leftLength.type == 1 ||
                coypbottomArr[index][indexs].ringhtLength.type == 1
              ) {
                coypbottomArr[index][indexs].imgActive = 2;
              }
              if (indexs == 0 || indexs == item.length - 1) {
                if (items.active == 3) {
                  coypbottomArr[index][indexs].imgActive = 2;
                }
              }
            } else {
              if (indexs == 0) {
                if (coypbottomArr[index][indexs].active == 3) {
                  coyptopArr[index][indexs].imgActive = 2;
                }
              }
            }
          }

          if (coypbottomArr[index][indexs].active == 3) {
            var indexArr = this.ifFloatingLine(
              indexs,
              coypbottomArr[index],
              item
            );
            if (indexArr.length == coypbottomArr[index].length) {
              indexArr.forEach((indexList, i) => {
                coypbottomArr[index][indexList].redType = 1;
              });
            }
          }

          if (items.active != "" && coypbottomArr[index][indexs].active != "") {
            if (items.active != 3) {
              if (indexs == 0) {
                items.leftLength = "a";
              } else {
                if (coypbottomArr[index][indexs - 1] != undefined) {
                  if (
                    coypbottomArr[index][indexs - 1].active != "" &&
                    item[indexs - 1].active != ""
                  ) {
                    items.leftLength = this.ifIndexSize(
                      this.getbothSidesindex(
                        item,
                        indexs,
                        1,
                        coypbottomArr[index][indexs]
                      ),
                      this.getbothSidesindex(
                        coypbottomArr[index],
                        indexs,
                        1,
                        item
                      ),
                      2
                    );
                  }
                }
              }
              if (indexs == item.length - 1) {
                items.ringhtLength = "a";
              } else {
                items.ringhtLength = this.ifIndexSize(
                  this.getbothSidesindex(
                    item,
                    indexs + 1,
                    0,
                    coypbottomArr[index][indexs]
                  ),
                  this.getbothSidesindex(coypbottomArr[index], indexs, 0, item),
                  1
                );
              }

              if (items.leftLength == undefined) {
                if (items.ringhtLength.type == 0) {
                  items.imgActive = 2;
                }
              } else {
                if (
                  items.ringhtLength.type == 0 ||
                  items.leftLength.type == 0
                ) {
                  items.imgActive = 2;
                }
              }
            } else {
              var indexArr = this.ifFloatingLine(
                indexs,
                item,
                coypbottomArr[index]
              );
              if (indexArr.length == item.length) {
                this.$emit("limit", 1);
                indexArr.forEach((indexList, i) => {
                  item[indexList].redType = 1;
                });
              }
            }
          }
        });
      });
      this.canvasImglw(coyptopArr, 1, coypbottomArr);
      this.canvasImglw(coypbottomArr, 2, coyptopArr);
    },

    setTable() {
      this.$nextTick(() => {
        clearTimeout(this.t);
        this.t = setTimeout(() => {
          this.cottonWool(); //渲染逻辑
        }, 140);
      });
    },
  },
  watch: {
    needleDisk: {
      //监听针盘
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
    Syringe: {
      //监听针筒
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
    roadNumber: {
      //监听路数
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
    needLeList: {
      //监听织针
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
    needleAlignmentValue: {
      //监听织针类型
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
  },
  mounted() {
    this.setTable();
  },
};
</script>

<style lang="less">
@import url("./doubleSideimg.less");
</style>>
