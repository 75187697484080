<template>
  <div class="oneSideimg">
    <div class="oneSideimgTop">
      <div class="oneSideimgTitle">意匠图</div>
      <div class="line">
        <div class="lineList">
          <div class="block">
            <div class="cq"></div>
          </div>
          <span>成圈</span>
        </div>
        <div class="lineList">
          <img src="@/images/onseSidecetcircle@2x.png" alt="" />
          <span>集圈</span>
        </div>
        <div class="lineList">
          <img src="@/images/floatLine@2x.png" alt="" />
          <span>浮线</span>
        </div>
      </div>
    </div>
    <div
      class="renderImg"
      @mouseover="sethiddenScrollbar(true)"
      @mouseout="sethiddenScrollbar(false)"
    >
      <div class="renderImgTop">
        <span>路数</span>
        <div class="btnBox">
          <div @click="setSize(1)">
            <svg-icon icon-class="decrease" class="leftImgbtn"></svg-icon>
          </div>
          <div @click="setSize(2)">
            <svg-icon icon-class="increase" class="rigthImgbtn"></svg-icon>
          </div>
        </div>
      </div>
      <div class="lineTable">
        <!-- <div class="boxMask"></div> -->
        <GeminiScrollbar class="box" ref="Scroll">
          <div v-for="(item, index) in tableData" :key="index" class="tr">
            <div>
              <div
                class="td"
                v-for="(items, indexs) in item"
                :key="indexs"
                :style="{
                  width: tdSizeNumber * 7 + 'px',
                  height: tdSizeNumber * 7 + 'px',
                  borderTop: indexs == 0 ? 'none' : '',
                  borderRight: index == tdWidth - 1 ? 'none' : '',
                }"
              >
                <!-- @mouseover.stop="tableDataMouseover(index, indexs, items)"
                @mouseout.stop="tableDataMouseout(index, indexs, items)" -->
                <div
                  v-if="items.active == 1"
                  class="setCircle"
                  :style="{
                    width: tdSizeNumber * 7 - 4 + 'px',
                    height: tdSizeNumber * 7 - 4 + 'px',
                  }"
                ></div>
                <svg-icon
                  id="oneSideimg"
                  v-if="items.active == 2"
                  class="Circle"
                  :icon-class="
                    items.type ? 'YijiangCircle' : 'YijiangCircleDiscard'
                  "
                ></svg-icon>
                <svg-icon
                  id="oneSideimg"
                  v-if="items.active == 3"
                  class="Emerge"
                  :icon-class="
                    items.type ? 'YijiangEmerge' : 'YijiangEmergeDiscard'
                  "
                ></svg-icon>
              </div>
            </div>
          </div>
        </GeminiScrollbar>
      </div>
      <div class="Txt">织针</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "oneSideimg",
  props: ["triWork", "valueList", "roadNumber", "tableWidth", "pageType"],
  data() {
    return {
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
      tdSizeNumber: 3,
      tdWidth: 68,
      tableData: [],
      t: "",
      pupTime: "",
    };
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.Scroll.$el.childNodes[4].scrollTop =
        72 * this.tdSizeNumber * 7;
    });
  },
  mounted() {
    this.setTable();
    this.setTableWidth();
  },
  methods: {
    //监听滑入滑出控制父组件滚动条
    sethiddenScrollbar(type) {
      if (type != this.hiddenScrollbar) {
        this.hiddenScrollbar = type;
        this.$emit("hiddenScrollbar", type);
      }
    },

    // 设置表格宽度
    setTableWidth() {
      this.tableData.length = 0;
      var num = 0;
      this.valueList.forEach((item, index) => {
        if (item.value != "") {
          num++;
        }
        if (index == this.valueList.length - 1) {
          num = num > 31 ? num : 31;
          for (var j = 0; j < num; j++) {
            this.tableData.push(this.defaultArr());
          }
        }
      });
    },
    setSize(type) {
      //格子大小
      if (type == 1) {
        if (this.tdSizeNumber > 1) {
          this.tdSizeNumber--;
        }
      } else {
        if (this.tdSizeNumber < 5) {
          this.tdSizeNumber++;
        }
      }
    },

    defaultArr() {
      //默认数组
      var arr = [];
      var num = 0;
      if (this.pageType == 1) {
        num = this.roadNumber > 40 ? this.roadNumber : 39;
      } else {
        num = 72;
      }
      for (var i = 0; i < num; i++) {
        arr.push({
          type: true,
          active: 4,
        });
      }
      return arr;
    },

    setTable() {
      //渲染表格
      this.$nextTick(() => {
        this.$refs.Scroll.$el.childNodes[4].scrollTop =
          72 * this.tdSizeNumber * 7;
      });
      let { triWork, valueList, roadNumber, tableData } = this;
      var that = this;
      clearTimeout(this.t);
      this.t = setTimeout(() => {
        //判断是否存在参数
        var arr = JSON.parse(JSON.stringify(this.$store.state.index.clearType));
        arr.forEach((item, index) => {
          if (index < 3) {
            arr[index] = 0;
          }
        });
        
        for (var i = 0; i < triWork.length; i++) {
          if (triWork[i][0].type) {
            arr[0] = 1;
          }
        }

        for (var j = 0; j < valueList.length; j++) {
          if (valueList[j].value != "") {
            arr[1] = 1;
          }
        }

        if (roadNumber != 12) {
          arr[2] = 1;
        }
        this.$store.commit("index/setClearType", arr);

        //默认符合规范
        this.$emit("ifLogic", 0);

        valueList.forEach((item, index) => {
          if (valueList[index].value !== "") {
            for (var i = 0; i < triWork[0].length; i++) {
              if (valueList[index].value - 1 < 4) {
                if (tableData[index] == undefined) {
                  tableData.push(this.defaultArr());
                }
                if (i > roadNumber - 1) {
                  tableData[index][tableData[index].length - 1 - i].active = 4;
                  tableData[index][
                    tableData[index].length - 1 - i
                  ].type = false;
                } else {
                  tableData[index][tableData[index].length - 1 - i].active =
                    triWork[valueList[index].value - 1][i].active;
                  tableData[index][tableData[index].length - 1 - i].type = true;
                }
              }
            }
          } else {
            if (tableData[index] == undefined) {
              if (this.pageType == 2) {
                this.valueList.forEach((item, index) => {
                  tableData.push(this.defaultArr());
                });
              }
            } else {
              tableData[index].forEach((items, i) => {
                tableData[index][i].active = 4;
                tableData[index][i].type = true;
              });
            }
          }
        });
        that.tableData = tableData;
        that.ifLogic();
      }, 160);
    },

    ifLogic() {
      //判断表格逻辑
      var transversemsgPup = 1;
      var portraitmsgPup = 1;
      var transversemsgNum = 0;
      var portraitNum = 0;
      this.tableData.forEach((item, index) => {
        item.forEach((list, i) => {
          if (list.active != 1 && list.active != 4) {
            for (var j = i; j > i - 4; j--) {
              //纵向判断
              if (item[j] != undefined) {
                if (item[j].active == 2 || item[j].active == 3) {
                  portraitNum++;
                  if (portraitNum == 4) {
                    this.tableData[index][i].type = false;
                    this.tableData[index][i - 1].type = false;
                    this.tableData[index][i - 2].type = false;
                    this.tableData[index][i - 3].type = false;
                    this.tableData[index][i - 4].type = false;
                    this.$emit("ifLogic", 1);
                    portraitmsgPup = 2;
                    portraitNum = 0;
                  }
                } else {
                  portraitNum = 0;
                }
                if (j == i - 3) {
                  if (portraitNum != 4) {
                    portraitNum = 0;
                  }
                }
              } else {
                portraitNum = 0;
              }
            }

            for (var n = index; n < index + 4; n++) {
              //横向判断

              if (this.tableData[n][i] != undefined) {
                if (
                  this.tableData[n][i].active == 2 ||
                  this.tableData[n][i].active == 3
                ) {
                  transversemsgNum++;
                  if (transversemsgNum == 4) {
                    this.tableData[index][i].type = false;
                    this.tableData[index + 1][i].type = false;
                    this.tableData[index + 2][i].type = false;
                    this.tableData[index + 3][i].type = false;
                    this.$emit("ifLogic", 1);
                    transversemsgPup = 2;
                    transversemsgNum = 0;
                  }
                  if (n == index + 3) {
                    if (transversemsgNum != 4) {
                      transversemsgNum = 0;
                    }
                  }
                } else {
                  transversemsgNum = 0;
                }
              }
            }
          }
        });
      });
      if (transversemsgPup == 2) {
        this.$message({
          showClose: true,
          message: "意匠图中,水平方向连续浮线和集圈不能超过3个",
          type: "warning",
        });
        transversemsgPup = 1;
      }
      if (portraitmsgPup == 2) {
        this.$message({
          showClose: true,
          message: "意匠图中,垂直方向连续浮线和集圈不能超过3个",
          type: "warning",
        });
        portraitmsgPup = 1;
      }
      clearTimeout(this.pupTime);
    },
  },
  watch: {
    triWork: {
      //监听三角工艺
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
    valueList: {
      //监听织针
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
    roadNumber: {
      //监听路数
      handler(newVal, oldVal) {
        this.setTable();
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./oneSideimg.less");
</style>>
